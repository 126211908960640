import React from 'react';
import dynamic from 'next/dynamic';
import { connect } from 'react-redux';

import { currentPageNameDuck } from '../ducks';
import { getDealerIdFromSlug } from '../utilities';
import { pageNames } from '@atc/bonnet-paths';

const FindADealerPage = dynamic(() => import('../components/main/FindADealerPage'));

const DealerDetailsPage = dynamic(() => import('../components/main/DealerDetailsPage'));

const RouterPage = ({ pageName }) => {
    const isDealerSearchPage = pageName === pageNames.DEALER_SEARCH;

    return isDealerSearchPage
        ? <FindADealerPage />
        : <DealerDetailsPage />;
};

function mapStateToProps(state) {
    return {
        pageName: currentPageNameDuck.selectors.getDuckState(state),
    };
}

RouterPage.getInitialProps = async (ctx) => {
    const dealerId = getDealerIdFromSlug(ctx.query.slug);

    const getInitProps = dealerId
        ? (await import('../components/main/getInitialPropsDealerDetailsPage')).default
        : (await import('../components/main/getInitialPropsFindADealerPage')).default;

    const initProps = await getInitProps(ctx);

    return initProps;
};

export default connect(mapStateToProps)(RouterPage);
